<template>
  <div class="search">
    <Nav></Nav>
    <div class="search-content">
      <div class="search-info">
        <div class="keyWord">
          <span>关键字：</span>
          <el-input v-model="searchInfo.keyword"></el-input>
          <el-button @click="onSearch">搜索</el-button>
          <span v-if="heightSearch === false">您尚未登录，登录后可使用高级搜索</span>
        </div>
        <div class="search-height" v-if="heightSearch">
          <div class="author-con">
            <div class="author">
              <div class="author-search">作者：</div>
              <el-input placeholder="请输入作者" v-model="searchInfo.author" clearable></el-input>
            </div>
            <div class="keyword-select">
              <el-checkbox v-model="searchInfo.keyInMain">搜索稿件正文</el-checkbox>
              <el-checkbox v-model="searchInfo.keyInPic">搜索图片说明</el-checkbox>
            </div>
          </div>
          <div class="search-store">
            <div class="store">所属库：</div>
            <div class="store-list">
              <el-checkbox-group v-model="storeIds" @change="postStore">
                <el-checkbox v-for="searcgStore in canserachStore" :key="searcgStore.id" :label="searcgStore.id">{{ searcgStore.name }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <div class="input-line">
            <div class="event">
              <div class="event-name">发生时间：</div>
              <div class="input-line-content">
                <el-date-picker
                  v-model="searchInfo.eventFrom"
                  type="date"
                  placeholder="选择发生开始日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="searchInfo.eventTo"
                  type="date"
                  placeholder="选择发生结束日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  @keyup.enter.native="onSearch"
                ></el-date-picker>
              </div>
            </div>
            <div class="innerEdit">
              <div class="innertime-name">上传时间：</div>
              <div class="input-line-content">
                <el-date-picker
                  v-model="searchInfo.uploadFrom"
                  type="date"
                  placeholder="选择发生开始日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                ></el-date-picker
                >-
                <el-date-picker
                  v-model="searchInfo.uploadTo"
                  type="date"
                  placeholder="选择发生结束日期"
                  :editable="false"
                  :clearable="false"
                  value-format="yyyy-MM-dd"
                  @keyup.enter.native="onSearch"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="input-line">
            <div class="addr">
              <div class="addr-name">检索地址：</div>
              <div class="input-line-content">
                <el-cascader
                  :options="address"
                  :props="addr_picker_props"
                  width="320"
                  filterable
                  clearable
                  change-on-select
                  separator=">"
                  v-model="addr_picker_options"
                  @keyup.enter.native="onSearch"
                ></el-cascader>
              </div>
            </div>
            <div class="type">
              <div class="type-name">检索类型：</div>
              <div class="input-line-content">
                <el-cascader
                  :options="types"
                  width="320"
                  :props="types_picker_props"
                  filterable
                  change-on-select
                  clearable
                  separator=">"
                  v-model="type_picker_options"
                  @keyup.enter.native="onSearch"
                ></el-cascader>
              </div>
            </div>
          </div>
          <div class="pic-Search">
            <div class="select-pic">
              <el-checkbox v-model="checkedPic"></el-checkbox>
            </div>
            <div class="pic-upload">
              <el-upload
                class="avatar-uploader"
                accept=".jpg, .jpeg, .JPG, .JPEG"
                :action="headImage"
                :show-file-list="false"
                :on-success="onGetpicImage"
                :with-credentials="true"
                :before-upload="beforeAvatarUpload"
              >
                <div>
                  <el-button>以图搜图</el-button>
                </div>
                <div v-if="picimgURL" class="showimg" :style="{ backgroundImage: `url(` + picimgURL + `)` }"></div>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div class="search-page">
        <div class="pic-count">符合当前条件的稿件共有：{{ postCount }}</div>
        <div class="page-container">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="20"
            :hide-on-single-page="true"
            layout="prev, pager, next, jumper"
            :total="downTotal"
          ></el-pagination>
        </div>
      </div>
      <div class="search-con">
        <div class="post-list">
          <div class="post-box" v-for="(k, index) in postList" :key="k.id">
            <div class="part1">
              <div class="xu">序号</div>
              <div>{{ index + 1 }}</div>
            </div>
            <div class="part2">
              <div class="yu">预览</div>
              <div :style="{ backgroundImage: `url(` + k.imageURL + `)` }" @click="onLookPost(k.id)"></div>
            </div>
            <div class="part3">
              <div class="time">时间/地点/图片</div>
              <div>
                <div>上传时间：{{ k.upTime }}</div>
                <div>地点：{{ k.eventAddr }}</div>
                <div>图片数：{{ k.countPicOnnet }}</div>
              </div>
            </div>
            <div class="part4">
              <div class="title">图片标题/内容</div>
              <div class="four">
                <div class="four-title">{{ k.title }}</div>
                <div class="con">{{ k.mainText }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  name: 'Index',
  data: function () {
    return {
      page: 1,
      currentPage: 1,
      downTotal: 1,
      postList: [],
      postCount: '', // 稿件数
      searchInfo: {
        order: '0',
        keyInPic: true,
      },
      heightSearch: true, // 高级搜索
      storeIds: [1],
      addr_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      addr_picker_options: [], // 默认地址
      type_picker_options: [], // 默认地址
      types_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      address: [],
      types: [],
      canserachStore: [],
      checkedPic: false, // 是否打开以图搜图
      picSuccess: false, // 图片是否上传
      headImage: '', // 存放图片的本地地址
      picimgURL: '', // 以图搜图的图片地址
    }
  },
  created() {
    this.headImage = RD.post().queryPicByUploader_URL()
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      if (this.$route.query.flag == 2) {
        thiz.heightSearch = true
      }
      RD.user()
        .sync()
        .then((data) => {
          thiz.canserachStore = data.searchStores
        })
    },
    handleCurrentChange(val) {
      var thiz = this
      thiz.page = val
      thiz.currentPage = val
      thiz.onSearch(thiz.page)
    },
    onSearch() {
      var thiz = this
      thiz.postStore()

      // 地址
      if (thiz.addr_picker_options.length !== 0) {
        thiz.search.addrId = thiz.addr_picker_options[thiz.addr_picker_options.length - 1]
      }
      // 类型
      if (thiz.type_picker_options.length !== 0) {
        thiz.search.typeId = thiz.type_picker_options[thiz.type_picker_options.length - 1]
      }

      if (!thiz.picSuccess) {
        thiz.$message.error({ message: '请先上传要搜的图片', type: 'warning' })
        return
      }

      if (!thiz.checkedPic) {
        thiz.$message.error({ message: '请先勾选以图搜图', type: 'warning' })
        return
      }

      if (thiz.searchInfo.storeIds == undefined) {
        thiz.$message('必须勾选至少一个库')
      } else {
        console.log('mx')
        thiz.searchInfo.keyByImage = true
        RD.post()
          .query(thiz.searchInfo, thiz.page, 20)
          .then((data) => {
            console.log('sdss', data)
            data.list.forEach((i) => {
              i.imageURL = RD.pic().id(i.frontPicId).mImage_URL()
            })
            thiz.postList = data.list
            thiz.postCount = data.pager.total
            thiz.currentPage = data.pager.currentPage
            thiz.downTotal = data.pager.total
          })
      }
    },
    // 库选择
    postStore() {
      this.searchInfo.storeIds = []
      this.storeIds.forEach((value) => {
        this.searchInfo.storeIds.push(parseInt(value))
      })
    },
    // 查看稿件
    onLookPost(postId) {
      this.$router.push({
        path: '/post.html',
        query: { id: postId },
      })
    },
    // 图片上传成功后
    onGetpicImage(res, file) {
      console.log('res', res)
      console.log('file', file)
      this.picimgURL = URL.createObjectURL(file.raw)
      this.picSuccess = true
      this.checkedPic = true
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
  },
}
</script>

<style scoped lang="less">
.search {
  height: auto;
  background-color: #f1f1f1;
  .search-content {
    width: 1200px;
    margin: 0 auto;
    .search-info {
      height: auto;
      font-size: 1.3rem;
      .keyWord {
        .el-input {
          width: 200px;
        }
        .el-button {
          margin-left: 10px;
        }
        span {
          margin-left: 10px;
        }
      }
      .search-height {
        margin-top: 10px;
        .search-store {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          font-size: 1.3rem;
          text-align: left;
          .store {
            width: 6%;
            text-align: left;
          }
          .store-list {
            margin: 0px;
            .el-checkbox-group {
              display: flex;
              flex-wrap: wrap;
              .el-checkbox {
                width: 80px;
              }
            }
          }
        }
        .input-line {
          text-align: left;
          margin: 10px 0;
          padding: 0px;
          display: flex;
          .addr,
          .type {
            display: flex;
            margin-right: 10px;
            .addr-name,
            .type-name {
              width: 30%;
              height: 50px;
              line-height: 50px;
            }
            .input-line-content {
              width: 70%;
            }
          }
          .event,
          .innerEdit {
            display: flex;
            .event-name,
            .innertime-name {
              height: 50px;
              line-height: 50px;
            }
            .input-line-content {
              margin-top: 5px;
            }
          }
        }
        .pic-Search {
          height: auto;
          display: flex;
          flex-wrap: nowrap;
          margin-bottom: 10px;
          .select-pic {
            width: 22px;
            margin-right: 20px;
          }
          .pic-upload {
            width: 500px;
            height: 100%;
            ::v-deep.el-upload {
              display: flex;
              flex-wrap: wrap;
              text-align: left;
            }
            .showimg {
              width: 300px;
              height: 220px;
              margin-left: 20px;
              background: no-repeat 50% / contain;
            }
          }
        }
        .author-con {
          display: flex;
          height: 50px;
          position: relative;
          .author {
            display: flex;
            text-align: left;
            .author-search {
              height: 50px;
              width: 80px;
              line-height: 50px;
            }
          }
          .keyword-select {
            height: 50px;
            line-height: 50px;
            margin-left: 30px;
          }
        }
      }
    }
    .search-page {
      display: felx;
      position: relative;
      font-size: 1.3rem;
      font-weight: bold;
      height: 30px;
      .pic-count {
        width: 350px;
        height: 30px;
        text-align: left;
        position: absolute;
        top: 0px;
      }
      .page-container {
        position: absolute;
        right: 20px;
        top: 0px;
      }
    }
    .search-con {
      display: flex;
      border: 1px solid #ccc;
      .post-list {
        .post-box {
          height: 250px;
          border-bottom: 1px solid #ccc;
          display: flex;
          font-size: 1.3rem;
          .part1 {
            width: 70px;
            border-right: 1px solid #ccc;
            .xu {
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            div {
              height: 200px;
              line-height: 200px;
            }
          }
          .part2 {
            width: 320px;
            border-right: 1px solid #ccc;
            .yu {
              text-align: center;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            div {
              height: 78%;
              width: 100%;
              background: no-repeat 50% / cover;
              vertical-align: middle;
            }
          }
          .part3 {
            width: 250px;
            border-right: 1px solid #ccc;
            .time {
              text-align: center;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            div {
              text-align: left;
              height: 30px;
              line-height: 30px;
            }
          }
          .part4 {
            width: 555px;
            border-right: 1px solid #ccc;
            .title {
              text-align: center;
              height: 50px;
              line-height: 50px;
              border-bottom: 1px solid #ccc;
            }
            .four {
              height: 199px;
              overflow-y: auto;
              .four-title {
                text-align: left;
                margin-left: 5px;
                margin-bottom: 10px;
              }
              .con {
                text-align: left;
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
